// console.log('Hello World! from main js');

document.onload = function () {
    // console.log('Hello World! from main js');
    // document.getElementById('wrapper').classList.add('loaded');
    // document.getElementById('loadingState').classList.add('hidden');
    // var lazyLoadInstance = new LazyLoad({
    //     // Your custom settings go here
    // });
    const images = document.getElementsByTagName("img");
    for (let image of images) {
        image.addEventListener('load', fadeImg);
        image.style.opacity = '0';
    }

    function fadeImg() {
        this.style.transition = 'opacity 2s';
        this.style.opacity = '1';
    }
}

window.addEventListener('DOMContentLoaded', (event) => {

// Lightbox
Array.from(document.querySelectorAll("[data-lightbox]")).forEach(element => {
    element.onclick = (e) => {
      e.preventDefault();
      basicLightbox.create(`<img src="${element.href}">`).show();
    };
  });
});

var themeToggleDarkIcon = document.getElementById('theme-toggle-dark-icon');
var themeToggleLightIcon = document.getElementById('theme-toggle-light-icon');

// Change the icons inside the button based on previous settings
if (localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    themeToggleDarkIcon.classList.remove('hidden');
} else {
    themeToggleLightIcon.classList.remove('hidden');
}

var themeToggleBtn = document.getElementById('theme-toggle');

themeToggleBtn.addEventListener('click', function() {
    // console.log('theme toggle button clicked')
    // toggle icons inside button
    themeToggleDarkIcon.classList.toggle('hidden');
    themeToggleLightIcon.classList.toggle('hidden');

    // if set via local storage previously
    if (localStorage.getItem('color-theme')) {
        if (localStorage.getItem('color-theme') === 'light') {
            document.documentElement.classList.add('dark');
            localStorage.setItem('color-theme', 'dark');
            // console.log('daek theme set');
        } else {
            document.documentElement.classList.remove('dark');
            localStorage.setItem('color-theme', 'light');
            // console.log('light theme set');
        }

    // if NOT set via local storage previously
    } else {
        if (document.documentElement.classList.contains('dark')) {
            document.documentElement.classList.remove('dark');
            localStorage.setItem('color-theme', 'light');
        } else {
            document.documentElement.classList.add('dark');
            localStorage.setItem('color-theme', 'dark');
            console.log('dark dark set');
        }
    }
    
});

var themeToggleBtn = document.getElementById('theme-toggle-mobile');

window.onload = function () {
    // console.log(`Hello World! from home`);
  
    var wrapper = document.getElementById("wrapper");
    var loadingState = document.getElementById("loadingState");

    if(wrapper && loadingState) {
        wrapper.classList.add("loaded");
        loadingState.classList.add("hidden");
    }

      var lazyLoadInstance = new LazyLoad({
        // Your custom settings go here
      });
      const images = document.getElementsByClassName("lazy");
      for (let image of images) {
        image.addEventListener("load", fadeImg);
        image.style.opacity = "0";
      }
  
      function fadeImg () {
        this.style.transition = "opacity .5s";
        this.style.opacity = "1";
        // console.log(`Image faded in`);
      }
      

};